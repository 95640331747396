import { Component, Input } from '@angular/core';

@Component({
  selector: 'error-indicator',
  template: ` <div class="main inicio">
  <div class="container">
  <div class="panel panel-default pd">
  <div class="panel-heading"><i class="fa fa-exclamation-triangle"></i> Error en ejecución</div>
  <div class="panel-body">{{mensaje}}</div>
  </div>
  </div>
  </div>`
})
export class ErrorComponent {
  @Input() public mensaje: string = null;
}
