import { Component } from '@angular/core';

@Component({
  selector: 'app-loading-indicator',
  template: `<div id="loader2"><div class="cont"><div class="cube1"></div><div class="cube2">
  </div><div class="cube4"></div><div class="cube3"></div></div></div>`
})
export class LoadingIndicatorComponent  {

  constructor() { }

}
