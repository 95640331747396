import { Component, Input, OnInit, OnChanges } from '@angular/core';
declare var moment: any;

@Component({
  selector: 'app-fecha',
  template: `{{texto}}`
})
export class FechaComponent implements OnInit, OnChanges {
  @Input() public valor: number = null;
  @Input() public formato: string = null;

  public _epochMicrotimeDiff: number = 621355968000000000;
  public texto = "";

  constructor() { }

  public ngOnInit() {
  }

  public ngOnChanges() {
    let ticks = Number(this.valor);
    if (ticks === 0 || isNaN(ticks)) {
      this.texto = "";
    } else {
      let frmt = this.formato || "DD-MM-YYYY";

      this.texto = this.fmtFechaFormato(ticks, frmt);//HH:mm:ss 
    }
  }

  public fmtFechaFormato(unix: number, formato: string) {
    return moment.unix(unix).format(formato);
  }
}