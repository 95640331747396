import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FechaComponent } from './fecha.component';

@NgModule({
    declarations: [
        FechaComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        FechaComponent
    ]
})
export class FechaModule { }